
import { Component, Vue } from 'vue-property-decorator'
import DeltaresLogin from '@/components/DeltaresLogin.vue'

@Component({
  components: {
    DeltaresLogin
  }
})
export default class LoginView extends Vue {}
