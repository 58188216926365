
import { Vue, Component } from 'vue-property-decorator'
import "flag-icons/css/flag-icons.min.css";

@Component
export default class LocaleControl extends Vue {
  langs!: {id: string; label: string, flag: string}[]

  created (): void {
    this.langs = [
      {
        id: 'nl',
        label: 'settings.nl',
        flag: 'nl'
      }, {
        id: 'en',
        label: 'settings.en',
        flag: 'us'
      }
    ]
  }
}
