import { render, staticRenderFns } from "./ArchiveDisplaySelectionComponent.vue?vue&type=template&id=2968eb97&scoped=true&"
import script from "./ArchiveDisplaySelectionComponent.vue?vue&type=script&lang=ts&"
export * from "./ArchiveDisplaySelectionComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2968eb97",
  null
  
)

export default component.exports