
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DateTime } from 'luxon'

export interface WMSLayerControlValue {
  name: string;
  active: boolean;
}

@Component
export default class WMSLayerControl extends Vue {
  @Prop({ default: () => [] })
  items!: any[];

  @Prop({ default: () => { return {
    name: '',
  }}}) value!: WMSLayerControlValue;

  @Prop({ default: '' })
  time!: string;

  @Prop({ default: () => { return new Date() } })
  timeIndex!: Date;

  @Prop({ default: true, type: Boolean})
  showLayer!: boolean

  layerName: string = ''
  currentLayerMenu = false

  // TODO: make value a object with layer, active, time
  mounted() {
    this.valueChange();
  }

  onChange(id: string) {
    this.$emit("change", { name: id} );
  }

  closeMenu() {
    this.currentLayerMenu = false
  }

  get formatedTime(): string{
    if ( isNaN(this.timeIndex.getTime()) ) {
      return '--:--'
    } else {
      const format = 'HH:mm ZZZZ'
      const timeZone = 'Europe/Amsterdam'
      const dateTime = DateTime.fromJSDate(this.timeIndex).setZone( timeZone ).setLocale('en-GB')
      return dateTime.toFormat(format)
    }
  }

  get layerLabel(): string {
    const selectedLayer = this.items?.find((layer: any) => { return layer.name === this.layerName})
    if (selectedLayer) {
      return this.getTitle(selectedLayer)
    }
    return ''
  }

  @Watch("value", { deep: true})
  valueChange() {
    if (this.value === null) {
      this.layerName = ''
    } else {
      this.layerName = this.value.name;
    }
  }

  get active(): boolean {
    return this.showLayer
  }

  set active(value: boolean) {
    this.$emit('update:showLayer', value)
  }

  getTitle(item: any) {
    return item.title === '' ? item.name : item.title
  }

}
