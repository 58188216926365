
import { Component, Prop, Vue } from 'vue-property-decorator'
// eslint-disable-next-line no-unused-vars
import { ColumnItem } from './ColumnItem'

@Component
export default class TreeMenu extends Vue {
  @Prop({ default: () => { return [] } }) items!: ColumnItem[]
  @Prop({ default: () => { return [] } }) open!: string[]
  @Prop({ default: () => { return [] } }) active!: string[]
}
