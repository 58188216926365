
import { Vue, Component } from 'vue-property-decorator'
import Default from '@/layouts/Default.vue'
import Empty from '@/layouts/Empty.vue'
import { namespace } from 'vuex-class'
import { WebOcComponent } from '@/store/modules/fews-config/types'
import { routesViews } from '@/router'

const fewsConfigModule = namespace('fewsconfig')

@Component({
  components: {
    Default,
    Empty
  }
})
export default class App extends Vue {
  @fewsConfigModule.State('components')
    webOcComponents!: { [key: string]: WebOcComponent }
  @fewsConfigModule.Action('setFewsConfig')
    setFewsConfig!: () => Promise<void>

  async created(): Promise<void> {
    if (this.$config.authenticationIsEnabled) {
      if (this.$route.path === "/auth/callback") {
        const user = await this.$auth.userManager.signinRedirectCallback()
        const path: string = user.state === null ? '/home' : user.state as string
        this.$router.push({ path })
      }
      const user = await this.$auth.userManager.getUser()
      if (user !== null) await this.setFewsConfig()
    } else {
      await this.setFewsConfig()
    }
    this.setRoutes()
  }

  setRoutes(): void {
    Object.values(this.webOcComponents).forEach(webOcComponent => {
      const route = routesViews.find((route) => route.name === webOcComponent.type)
      if (route !== undefined) this.$router.addRoute(route)
    })
  }

  get layout (): string {
    let layout = 'default'
    this.$route.matched.some(
      record => {
        if (record.meta.layout) layout = record.meta.layout
        return record.meta.layout
      }
    )
    return layout
  }
}
