
import { Vue, Component, Prop } from "vue-property-decorator";

export interface LocationsLayerControlValue {
  name: string;
  active: boolean;
}

@Component
export default class LocationsLayerControl extends Vue {
  @Prop({ default: () => { return {
    name: '',
    active: true
  }}}) value!: LocationsLayerControlValue;

  show = true

  onShowChange() {
    this.$emit('input', this.show)
  }
}
