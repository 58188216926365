
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ColourTheme } from '@/lib/Theme'

interface ThemeOption {
  id: string
  theme: ColourTheme
  icon: string
  tooltip: string
}

@Component
export default class ThemeSelector extends Vue {
  @Prop({ default: () => ColourTheme.Auto }) value!: ColourTheme
  selectedIndex: number = 0

  options: ThemeOption[] = [
    {
      id: 'auto',
      theme: ColourTheme.Auto,
      icon: 'mdi-theme-light-dark',
      tooltip: 'Select theme automatically'
    },
    {
      id: 'light',
      theme: ColourTheme.Light,
      icon: 'mdi-weather-sunny',
      tooltip: 'Select light theme'
    },
    {
      id: 'dark',
      theme: ColourTheme.Dark,
      icon: 'mdi-weather-night',
      tooltip: 'Select dark theme'
    }
  ]

  mounted() {
    this.onValueChange()
  }

  selectTheme() {
    this.$emit('input', this.options[this.selectedIndex].theme)
  }

  @Watch('value')
  onValueChange() {
    const index = this.options.findIndex(option => option.theme === this.value)
    // If we specify a theme that we cannot find, fall back to the first option.
    this.selectedIndex = index < 0 ? 0 : index
  }
}
