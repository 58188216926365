
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DeltaresLogin extends Vue {
  @Prop()
    name!: string

  login (): void {
    const redirect = this.$route.query.redirect || '/home'
    this.$auth.userManager.signinRedirect({ state: redirect })
  }
}
