
import { Vue, Component, Prop } from "vue-property-decorator";
import TimeSeriesChart from '@/components/TimeSeriesComponent/ConfigurableChart.vue'
import TimeSeriesTable from '@/components/TimeSeriesComponent/TimeSeriesTable.vue'
import WindowComponent from "@/components/Layout/WindowComponent.vue"
import { Series } from "@/lib/TimeSeries";
import type { DisplayConfig } from '@/lib/Layout/DisplayConfig'

@Component({
  components: {
    TimeSeriesChart,
    TimeSeriesTable,
    WindowComponent,
  },
})
export default class ComponentsPanel extends Vue {

  @Prop({ default: true })
  panel!: boolean[]

  @Prop({ default: true })
  focus!: boolean[]

  @Prop({ default: () => { return {} } })
  displays!: { [key: string]: DisplayConfig}

  @Prop({default: () => {return {}} })
  series!: Record<string, Series>
}
