
import { Component, Vue } from 'vue-property-decorator'
import { Duration, DateTime } from 'luxon'
import { namespace } from 'vuex-class';

const sytemTimeModule = namespace('systemTime')

@Component
export default class TimeControl extends Vue {
  @sytemTimeModule.State('systemTime')
  now!: Date

  @sytemTimeModule.Getter('getSystemTime')
  getSystemTime!: () => Date

  @sytemTimeModule.Mutation('setInterval')
  storeSetInterval!: (payload: any) => Date

  selectedInterval: string|null = null
  intervalItems = [
    '-P1D',
    '-P7D',
  ]

  setInterval(interval: string | null) {
    const now = this.getSystemTime()
    this.selectedInterval = interval
    if (interval == null) {
      this.storeSetInterval({ startTime: null, endTime: null})
    } else {
      const duration = Duration.fromISO(interval)
      const startDateTime = DateTime.fromJSDate(now).plus( duration)
      const endDateTime = DateTime.fromJSDate(now)
      this.storeSetInterval({ startTime: startDateTime.toJSDate(), endTime: endDateTime.toJSDate()})
    }
  }

  localeString(item: string) {
    const duration = Duration.fromISO(item)
    const startDateTime = DateTime.fromJSDate(this.now).plus( duration)
    return startDateTime.toRelative()
  }

}
