
import {Component, Vue} from 'vue-property-decorator'
import RunningTasks from "@/components/systemmonitor/RunningTasks.vue";
import ImportStatusComponent from "@/components/systemmonitor/ImportStatusComponent.vue";

@Component({
  components: {ImportStatusComponent, RunningTasks}
})
export default class DisplayComponent extends Vue {
  baseUrl: string = "";
  selectedTab: number = 0;

  mounted() {
    this.baseUrl = this.$config.get('VUE_APP_FEWS_WEBSERVICES_URL')
  }

}
