
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class DateTimeSlider extends Vue {
  @Prop({ default: () => { return new Date(1970) } }) private value!: Date
  @Prop({ default: () => { return [new Date(1970)] } }) private dates!: Date[]
  @Prop({ default: false, type: Boolean }) private loading!: boolean
  @Prop({ default: false, type: Boolean }) private now!: boolean

  index: number = 0
  currentDate!: Date
  useNow: boolean = true
  isPlaying: boolean = false
  intervalTimer: any = 0
  hideLabel = true

  mounted (): void {
    this.updateIndexValueChange()
    this.currentDate = this.value
    this.$emit('update:now', this.useNow)
  }

  get max (): number {
    return Math.max(0, this.dates.length - 1)
  }

  get dateString (): string {
    return this.dates[this.index] ? this.dates[this.index].toLocaleString() : ''
  }

  get nowColor (): string {
    return this.useNow ? 'orange' : ''
  }

  get playColor (): string {
    return this.isPlaying ? 'orange' : ''
  }

  togglePlay (): void {
    if (this.isPlaying) {
      this.isPlaying = false
      clearInterval(this.intervalTimer)
      this.intervalTimer = 0
    } else {
      this.isPlaying = true
      this.useNow = false
      this.intervalTimer = setInterval(this.play, 1000)
    }
  }

  stopPlay (): void {
    if (this.intervalTimer) {
      this.isPlaying = false
      clearInterval(this.intervalTimer)
      this.intervalTimer = 0
    }
  }

  play (): void {
    if (this.max === this.index) {
      this.stopPlay()
    } else {
      this.increment()
    }
  }

  @Watch('value')
  updateIndexValueChange (): void {
    if (this.value && this.dates) {
      this.index = this.dates.findIndex((date: Date) => { return this.value.getTime() === date.getTime() })
      this.currentDate = this.dates[this.index]
    }
  }

  @Watch('now')
  updateNow (): void {
    this.useNow = this.now
  }

  toggleNow (): void {
    this.useNow = !this.useNow
    if (this.useNow) {
      const now = new Date()
      for (let i = 0; i < this.dates.length; i++) { // Loop the array
        if (this.dates[i] > now) {
          this.index = Math.max(0, i - 1)
          break
        }
      }
      this.stopPlay()
      this.updateDate()
    }
    this.$emit('update:now', this.useNow)
  }

  backward (step?: number): void {
    if (this.useNow) {
      this.useNow = false
      this.$emit('update:now', this.useNow)
    }
    this.decrement(step)
    if (this.isPlaying) this.stopPlay()
    this.intervalTimer = setInterval(() => this.decrement(step), 200)
  }

  forward (step?: number): void {
    if (this.useNow) {
      this.useNow = false
      this.$emit('update:now', this.useNow)
    }
    this.increment(step)
    if (this.isPlaying) this.stopPlay()
    this.intervalTimer = setInterval(() => this.increment(step), 200)
  }

  increment (step = 1): void {
    this.index = Math.min(this.max, this.index + step)
    this.inputChanged()
  }

  decrement (step = 1): void {
    this.index = Math.max(0, this.index - step)
    this.inputChanged()
  }

  updateDate (): void {
    this.currentDate = this.dates[this.index]
  }

  onInput (): void {
    this.updateDate()
    this.inputChanged()
  }

  inputChanged (): void {
    if (this.useNow) {
      this.useNow = false
      this.$emit('update:now', this.useNow)
    }
    if (this.dates[this.index]) this.$emit('input', this.dates[this.index])
  }
}
