
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {PiArchiveWebserviceProvider} from "@deltares/fews-pi-requests";
import type {
  ArchiveLocationsFilter,
  ArchiveParameter,
  Location,
  ParametersFilter,
} from "@deltares/fews-pi-requests";
import { DocumentFormat } from "@deltares/fews-pi-requests";
import {FeatureCollection, Geometry} from "geojson";

@Component
export default class ArchiveDisplaySelectionComponent extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  }) selectedLocations!: Location[]
  selectedLocationsSelectBox: Location[] = [];
  baseUrl!: string;
  archiveWebServiceProvider: PiArchiveWebserviceProvider = {} as PiArchiveWebserviceProvider;
  menuStartTime: boolean = false;
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  menuEndTime: boolean = false;
  startDate: string = "";
  endDate: string = "";
  startTime: string = "00:00";
  endTime: string = "00:00";
  selectedAreas: string[] = [];
  areas: string[] = ["area1", "area2"];
  selectedModuleInstances: string[] = [];
  moduleInstances: string[] = ["moduleInstance1", "moduleInstance2"];
  selectedSources: string[] = [];
  sources: string[] = ["source1", "source2"];
  locations: Location[] = [];
  selectedParameters: string[] = [];
  parameters: ArchiveParameter[] = [];
  selectedTimeSeriesType: string = "";
  timeSeriesTypes: string[] = ["external historical", "external forecasting", "simulated forecasting", "simulated historical"];


  created(): void {
    this.baseUrl = this.$config.get('VUE_APP_FEWS_ARCHIVE_WEBSERVICES_URL')
  }

  @Watch('selectedLocations')
  updateSelectedLocations(): void {
    this.selectedLocationsSelectBox = this.selectedLocations;
  }

  updateLocations(): void {
    this.$emit("update:selectedLocations", this.selectedLocationsSelectBox)
  }

  async mounted(): Promise<void> {
    this.archiveWebServiceProvider = new PiArchiveWebserviceProvider(this.baseUrl);
    const archiveLocationsFilter = {
      documentFormat: DocumentFormat.GEO_JSON
    }
    const locationsResponse = await this.archiveWebServiceProvider.getLocations(archiveLocationsFilter);
    const geoJsonResponse = (((await locationsResponse) as any) as FeatureCollection<Geometry, Location>);
    this.locations = geoJsonResponse.features.map((feature) => feature.properties);

    const archiveParametersFilter = {
      documentFormat: DocumentFormat.PI_JSON
    }
    const parametersResponse = await this.archiveWebServiceProvider.getParameters(archiveParametersFilter);
    this.parameters = parametersResponse.parameters;
  }

}
