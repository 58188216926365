
import { Component, Mixins } from 'vue-property-decorator'
import ThemeMixin from '@/mixins/ThemeMixin'
import ThemeSelector from '@/components/ThemeSelector.vue'
import LocaleControl from './LocaleControl.vue'
import packageConfig from '../../package.json'

@Component({
  components: {
    ThemeSelector,
    LocaleControl
  }
})
export default class CogMenu extends Mixins(ThemeMixin) {
  packageVersion = packageConfig.version
}
