
import { DisplayType } from '@/lib/Layout/DisplayConfig'
import { Vue, Component, Prop } from 'vue-property-decorator'


@Component
export default class WindowComponent extends Vue {
  @Prop( {default: '', type: String}) title!: string
  fullscreen = false

  @Prop( {default: () => { return [] }}) displayTypes!: DisplayType[]

  tab: number = 0

  get fullscreenIcon (): string {
    return this.fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
  }

  toggleFullscreen (): void {
    this.fullscreen = !this.fullscreen
  }

  tabLabel (displayType: DisplayType): string {
    switch (displayType) {
      case DisplayType.TimeSeriesChart:
        return "Chart"
      case DisplayType.TimeSeriesTable:
        return "Table";
      default:
        return displayType ?? "";
    }
  }
}

