
import { Component, Vue } from 'vue-property-decorator'
import { User } from 'oidc-client-ts'

function initialsFromName (givenName: string): string {
  let initials = ''
  for (let i = 0; i < givenName.length; i++) {
    const character = givenName[i]
    if (character !== character.toLowerCase()) { initials = initials + character }
    if (initials.length == 2) return initials;
  }
  return initials
}

@Component
export default class LoginComponent extends Vue {
  user: User | null = null

  mounted (): void {
    this.$auth.userManager
      .getUser()
      .then(user => {
        this.user = user
      })
      .catch(err => {
        console.log({ err })
      })
  }

  login (): void {
    this.$auth.userManager.signinRedirect({ state: this.$route.path })
  }

  logout (): void {
    this.$auth.userManager.signoutRedirect({ state: '/portal' })
  }

  get initials (): string {
    if (this.user?.profile?.name === undefined) return ''
    return initialsFromName(this.user.profile.name)
  }
}
