
import { Component, Vue } from 'vue-property-decorator'
import CogMenu from '@/components/CogMenu.vue'
import TimeControl from '@/components/timecontrol/TimeControl.vue'
import LoginComponent from '@/components/LoginComponent.vue'
import { namespace } from 'vuex-class'
import { Alert } from '@/store/modules/alerts/types'
import type { WebOcComponent } from '@/store/modules/fews-config/types'
import { ComponentTypeEnum } from '@/store/modules/fews-config/types'

const alertsModule = namespace('alerts')
const fewsConfigModule = namespace('fewsconfig')

@Component({
  components: {
    CogMenu,
    LoginComponent,
    TimeControl,
  }
})
export default class Default extends Vue {
  @alertsModule.Getter('listActive')
    activeAlerts!: Alert[]
  @alertsModule.State('alerts')
    alerts!: Alert[]
  @fewsConfigModule.State('components')
    webOcComponents!: { [key: string]: WebOcComponent }
  @fewsConfigModule.Action('loadConfig')
    loadConfig!: () => void

  mounted(): void {
    this.loadConfig()
  }

  drawer: boolean | null = null

  toggleDrawer (): void {
    this.drawer = !this.drawer
  }

  get showAlerts() {
    return this.activeAlerts.length > 0
  }

  dismissAlert(alert: Alert, value: boolean) {
    alert.active = value
  }

  getMenuIcon (componentConfig: WebOcComponent): string {
    if (componentConfig.icon !== undefined) return componentConfig.icon
    switch (componentConfig.type) {
      case ComponentTypeEnum.DataViewer:
        return 'mdi-archive-search'
      case ComponentTypeEnum.SpatialDisplay:
        return 'mdi-map'
      case ComponentTypeEnum.SchematicStatusDisplay:
        return 'mdi-application-brackets-outline'
      case ComponentTypeEnum.TimeSeriesDisplay:
        return 'mdi-chart-sankey'
      case ComponentTypeEnum.SystemMonitor:
        return 'mdi-clipboard-list'
      default: return ''
    }
  }

  get menuItems (): {id: string, to: {name: string}, title: string, icon: string}[] {
    const menuItems = Object.values(this.webOcComponents).map(componentConfig => {
      return {
        id: componentConfig.id,
        to: { name: componentConfig.type },
        title: componentConfig.title ?? '',
        icon: this.getMenuIcon(componentConfig)
      }
    })
    return menuItems
  }
}
