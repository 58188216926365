
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ChartConfig} from './lib/ChartConfig'
import {ChartSeries} from './lib/ChartSeries'
import {Series} from '@/lib/TimeSeries'
import {getUniqueSeriesIds} from "@/components/TimeSeriesComponent/lib/getUniqueSeriesIds";
import {TableHeaders} from "@/components/TimeSeriesComponent/lib/TableHeaders";
import {createTableHeaders} from "@/components/TimeSeriesComponent/lib/createTableHeaders";
import {createTableData} from './lib/createTableData';

@Component
export default class TimeSeriesTable extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  value!: ChartConfig

  @Prop({
    default: () => {
      return {}
    }
  })
  series!: Record<string, Series>

  seriesIds: string[] = []
  tableData: Record<string, unknown>[] = []
  tableHeaders: TableHeaders[] = []

  mounted() {
    this.onSeriesChange()
  }

  @Watch('series')
  onSeriesChange(): void {
    this.seriesIds = getUniqueSeriesIds(this.value.series)
    this.tableHeaders = createTableHeaders(this.value.series, this.seriesIds)
    this.tableData = createTableData(this.value.series, this.series, this.seriesIds)
  }
}
