
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import ColumnMenu from '@/components/ColumnMenu.vue'
import TreeMenu from '@/components/TreeMenu.vue'
import {ColumnItem} from '@/components/ColumnItem'
import ComponentsPanel from '@/components/Layout/ComponentsPanel.vue'
import TimeSeriesMixin from '@/mixins/TimeSeriesMixin'
import {DisplayConfig, DisplayType} from '@/lib/Layout/DisplayConfig'
import {PiWebserviceProvider, ActionRequest} from "@deltares/fews-pi-requests";
import PiRequestsMixin from "@/mixins/PiRequestsMixin"
import type { TopologyActionFilter, ActionsResponse, TopologyNode } from "@deltares/fews-pi-requests";
import { namespace } from 'vuex-class'
import { timeSeriesDisplayToChartConfig } from '@/lib/ChartConfig/timeSeriesDisplayToChartConfig'

const sytemTimeModule = namespace('systemTime')

@Component({
  components: {
    ColumnMenu,
    TreeMenu,
    ComponentsPanel
  }
})
export default class TimeSeriesDisplay extends Mixins(TimeSeriesMixin, PiRequestsMixin) {
  @Prop({default: '', type: String})
  nodeId!: string

  @sytemTimeModule.State('startTime')
    startTime!: Date
  @sytemTimeModule.State('endTime')
    endTime!: Date

  urlTopologyNodeMap: Map<string,string> = new Map<string, string>()
  selectedItem: number = -1
  active: string[] = []
  open: string[] = []
  items: ColumnItem[] = []
  viewMode = 0
  url: string | undefined = "";
  baseUrl!: string
  allDisplays: DisplayConfig[][] = []
  displays: DisplayConfig[] = []
  requests: ActionRequest[][] = [];
  plots: string[] = [];
  webServiceProvider: PiWebserviceProvider = {} as PiWebserviceProvider;

  created(): void {
    this.baseUrl = this.$config.get('VUE_APP_FEWS_WEBSERVICES_URL')
  }

  async mounted(): Promise<void> {
    const transformRequestFn = this.getTransformRequest()
    this.webServiceProvider = new PiWebserviceProvider(this.baseUrl, {transformRequestFn});
    await this.loadNodes()
    await this.onNodeChange()
  }

  anyChildNodeIsVisible(nodes: TopologyNode[] | undefined): boolean {
    if (nodes === undefined) return false;
    for (const node of nodes) {
      if (this.topologyNodeIsVisible(node)) return true
    }
    return false;
  }

  topologyNodeIsVisible(node: TopologyNode): boolean {
    if (node.url !== undefined) return true;
    if (node.displayId !== undefined) return true;
    if (node.displayGroups !== undefined && node.displayGroups.length > 0) return true
    return this.anyChildNodeIsVisible(node.topologyNodes);
  }

  fillNodeMap(node: TopologyNode, map: Map<string, string>) {
    if (node.url !== undefined) {
      map.set(node.id, node.url)
    }
    node.topologyNodes?.forEach(childNode => this.fillNodeMap(childNode, map));
  }

  getIcon(node: TopologyNode): string | undefined {
    if (node.url) return 'mdi-share';
    return undefined;
  }
  async loadNodes(): Promise<void> {
    let nodes = await this.webServiceProvider.getTopologyNodes();
    this.urlTopologyNodeMap.clear();
    nodes.topologyNodes.forEach(node => this.fillNodeMap(node, this.urlTopologyNodeMap));

    const recursiveUpdateNode = (nodes: TopologyNode[]) => {
      return nodes.filter(node => this.topologyNodeIsVisible(node)).map((node) => {
        const result: ColumnItem = {
          id: node.id,
          name: node.name,
          icon: this.getIcon(node)
        }
        if (node.topologyNodes) {
          result.children = recursiveUpdateNode(node.topologyNodes)
        } else {
          if(node.url !== undefined) {
            result.href = node.url
            result.target = node.url
          } else {
            result.to = {
              name: 'TimeSeriesDisplay',
              params: {
                nodeId: node.id
              }
            }
          }
        }
        return result
      })
    }
    const items: ColumnItem[] = [
      {
        id: 'root',
        name: 'Topology',
        children: recursiveUpdateNode(nodes.topologyNodes)
      }
    ]

    this.items = items
    this.open = [items[0].id]
  }

  @Watch('$store.state.systemTime.startTime')
  async onTimeChanged(): Promise<void> {
    await this.loadTimeSeries(this.selectedItem);
  }

  @Watch('selectedItem')
  async onPlotChanged(): Promise<void> {
    this.displays = this.allDisplays[this.selectedItem];
    await this.loadTimeSeries(this.selectedItem);
  }

  @Watch('nodeId')
  async onNodeChange(): Promise<void> {
    const filter = {} as TopologyActionFilter;
    filter.nodeId = this.nodeId;
    const response: ActionsResponse = await this.webServiceProvider.getTopologyActions(filter);
    this.selectedItem = 0;
    this.allDisplays = [];
    this.requests = [];
    this.plots = [];
    this.displays = [];
    for (const result of response.results) {
      if (result.config === undefined) continue;
      const display: DisplayConfig[] = [];
      const title = result.config.timeSeriesDisplay.title ?? ''
      result.config.timeSeriesDisplay.subplots?.forEach((subPlot, index) => {
        display.push({
          id: `${title}-${index}`,
          types: [DisplayType.TimeSeriesChart, DisplayType.TimeSeriesTable],
          class: 'single',
          title: title,
          config: timeSeriesDisplayToChartConfig(subPlot, title)
        })
      })
      this.allDisplays.push(display);
      this.requests.push(result.requests);
      this.plots.push(title)
    }
    this.displays = this.allDisplays.length > 0 ? this.allDisplays[0] : [];
    await this.loadTimeSeries(0);
  }

  private async loadTimeSeries(index: number) {
    this.updateTimeSeries(this.requests[index], {startTime: this.startTime, endTime: this.endTime, thinning: true})
  }

}
